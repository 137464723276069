<template>
    <ODataGrid :dataObject="dataObject" :columns="[]" :eagerGridControl="setGridRef">
        <template #cardheader>
            <slot name="cardheader"/>
        </template>
        <template #default>
            <slot/>
        </template>
    </ODataGrid>
</template>

<script setup lang="ts">
import { useAppGlobals, usePlugin } from 'o365.current.moduels.helpers.ts';
import CoreCompoennts from 'o365.vue.plugins.CoreComponents.js';
import { getDataObjectById } from 'o365.vue.ts';
import ODataGrid from 'o365.vue.components.DataGrid.vue';
import { dataGridControlKey } from 'o365.modules.vue.injectionKeys.js';
import { provide, ref } from 'vue';

interface Props {
    dataObjectId: string;
};

useAppGlobals();
usePlugin(CoreCompoennts);


const props = defineProps<Props>();

const dataObject = getDataObjectById(props.dataObjectId);

let gridControl = ref(null);
function setGridRef(ctrl) { gridControl.value = ctrl.value; gridControl.value.isCustomElement = true; }
provide(dataGridControlKey, gridControl);

</script>